<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    v-model="internalValue"
    max-width="520"
    persistent
  >
    <template #activator="{on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs"/>
    </template>
    <div class="gray-0 py-9 px-9 px-sm-7" v-if="space">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">Delete Space “{{ space.title }}”</h5>
      </div>

      <v-divider class="mt-5 mb-8"/>

      <ValidationObserver v-slot="{ handleSubmit, valid }" tag="div" ref="form" class="form">
        <v-form @submit.prevent="handleSubmit(deleteHandler)">
          <div class="text-captions-1 gray-100--text">
            <p>This will permanently delete the Space “{{ space.title }}”. Deleting this Space will delete all
              associated data, such as projects, files, prototypes, for all users.</p>
            <p>To confirm, please enter the name of the Space.</p>
          </div>

          <ValidationProvider class="mb-7" tag="div"
                              name="space name" vid="name" :rules="`required|name_validate:${space.title}`">
            <div class="mb-2 text-body font-weight-light gray-100--text">Space name</div>
            <v-text-field
              dense
              outlined
              hide-details
              v-model="title"
              placeholder="Space name"
            />
          </ValidationProvider>

          <div class="d-flex flex-wrap justify-center justify-sm-end space-x-3">
            <UiBtn
              outlined
              width="136"
              color="accent"
              class="mt-4"
              @click="onCancel"
            >
              Cancel
            </UiBtn>
            <UiBtn
              width="216"
              color="accent"
              :disabled="!valid"
              class="mt-4"
              type="submit"
              :loading="deleteLoading"
            >
              Delete Space
            </UiBtn>
          </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-dialog>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
import {mapGetters} from "vuex";

export default {
  name: "DeleteSpaceModal",
  components: {UiBtn},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    space: {
      type: Object,
      default: null
    }
  },
  watch: {
    value(val) {
      if(!val) {
        this.title = ''
      }
    }
  },
  data() {
    return {
      deleteDialog: this.value,
      deleteLoading: false,
      title: ''
    }
  },
  computed:{
    ...mapGetters([
      'getSpaces',
      'getActiveSpace'
    ]),
    internalValue: {
      get() {
        return this.deleteDialog
      },
      set(value) {
        this.deleteDialog = value
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onCancel() {
      this.internalValue = false
      this.$emit('cancel')
    },
    async deleteHandler() {
      this.deleteLoading = true
      try {
        await this.$api.spaces.delete(this.space.id)

        const updatedSpaces = this.getSpaces.filter(space => {
          return space.id !== this.space.id
        })
        this.$emit('submit')
        await this.$store.dispatch('setSpaces', updatedSpaces)
        if (this.getActiveSpace?.id === this.space.id) {
          await this.$store.dispatch('setActiveSpace', null)
        }
        this.internalValue = false
      } catch (e) {
        console.error(e)
      } finally {
        this.deleteLoading = false
      }
    }
  }
}
</script>

