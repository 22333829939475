<template>
  <div>
    <div class="text-body-lg font-weight-medium mb-5">Information</div>
    <SpaceCreateForm v-model="form" @submit="submitHandler" v-if="edit">
      <template #action>
        <div class="d-flex align-center space-x-2">
          <UiBtn class="flex-fill fill-width" @click="edit = false" outlined color="accent">Cancel</UiBtn>
          <UiBtn class="flex-fill fill-width" color="accent" type="submit" :loading="loading">Save</UiBtn>
        </div>
      </template>
    </SpaceCreateForm>
    <div v-else class="space-info">
      <div class="space-info-grid">
        <div class="text-body gray-100--text font-weight-semi-bold">Name</div>
        <div class="text-body gray-100--text font-weight-light">{{ space.title }}</div>
        <div class="text-body gray-100--text font-weight-semi-bold">Icon</div>
        <div>
          <v-sheet :color="space.color" width="16" height="16" rounded="circle"/>
        </div>
      </div>
      <UiBtn @click="startEdit" v-if="canEdit" outlined color="accent" class="mt-5" width="140">
        <IconPen width="16" class="mr-2"/>
        Edit
      </UiBtn>
    </div>
    <template v-if="canEdit">
      <div class="text-body-lg font-weight-medium mb-2 mt-10">Delete Space</div>

      <DeleteSpaceModal :space="space" @submit="deleteHandler">
       <template #activator="{on, attrs}">
         <UiBtn color="accent" v-bind="attrs" v-on="on">Delete space permanently</UiBtn>
       </template>
      </DeleteSpaceModal>
    </template>

  </div>
</template>

<script>
import SpaceCreateForm from "@/components/spaces/SpaceCreateForm";
import UiBtn from "@/components/UI/UiBtn";
import {mapGetters} from "vuex";
import DeleteSpaceModal from "@/components/modals/DeleteSpaceModal";

export default {
  name: "SpaceGeneralView",
  components: {
    DeleteSpaceModal,
    IconPen: () => import('@/components/icons/IconPen'),
    UiBtn, SpaceCreateForm
  },
  props: {
    space: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      edit: false,
      deleteLoading: false,
      loading: false,
      title: '',
      form: {
        title: '',
        color: ''
      },
    }
  },
  computed: {
    ...mapGetters(['getSpaces', 'getActiveSpace']),
    canEdit() {
      return this.space.currentSpaceUser.user_role.name === this.$config.space.userRole.spaceAdmin
    }
  },
  methods: {
    startEdit() {
      this.form = JSON.parse(JSON.stringify(this.space))
      this.edit = true
    },
    async submitHandler(data) {
      this.loading = true
      const {color, title} = data
      try{
        const spaceUpdated = await this.$api.spaces.update(this.space.id, {
          title,
          color,
          scheme_id: this.space.scheme_id
        })
        const updatedSpaces = this.getSpaces.map(space => {
          return space.id !== spaceUpdated.data.id ? space : {
            ...space,
            ...spaceUpdated.data
          }
        })
        await this.$store.dispatch('setSpaces', updatedSpaces)
        this.$toast.open({
          message: `Space updated!`,
          type: 'success',
          position: 'top-right'
        });
        this.edit = false
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    deleteHandler() {
        this.$router.replace({name: 'Dashboard'})
    }
  }
}
</script>


<style scoped lang="scss">
.space-info {
  max-width: 365px;
  width: 100%;

  &-grid {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    row-gap: 16px;
    column-gap: 28px;
  }

}
</style>
